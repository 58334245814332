import { Alert, Grid, Snackbar } from '@mui/material';
import React from 'react';
import {
  selectSetting,
  setNotification,
} from '../../redux/features/settingSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import FiltersForm from './FiltersForm';
interface FiltersProps {}

const Filters: React.FunctionComponent<FiltersProps> = () => {
  const settingState = useAppSelector(selectSetting);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setNotification(''));
  };
  return (
    <Grid>
      <Grid
        sx={{
          display: 'flex',
          m: 4,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></Grid>
      <Grid
        sx={{
          display: 'flex',
          m: 4,
          alignItems: 'center',
        }}
      >
        <FiltersForm />
        {settingState.message && (
          <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity='error'
              sx={{ width: '100%' }}
            >
              {settingState.message}
            </Alert>
          </Snackbar>
        )}
      </Grid>
    </Grid>
  );
};

export default Filters;
