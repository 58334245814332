import { Grid, Box, Paper } from '@mui/material';
import React from 'react';
import Filters from '../Filters';
import Map from '../Map';
import Header from '../Header';

interface HomeProps {}

const paperContainer = {
  m: 2,
  height: `calc(100% - 28px)`,
};

const Home: React.FunctionComponent<HomeProps> = () => {
  return (
    <Grid>
      <Header />
      <Box style={{ height: '100vh' }}>
        <Grid
          container
          sx={{
            height: `calc(100% - 56px)`,
            backgroundColor: 'secondary.dark',
          }}
        >
          <Grid item md={4}>
            <Paper variant='outlined' sx={{ ...paperContainer }}>
              <Filters />
            </Paper>
          </Grid>
          <Grid item md={8}>
            <Box sx={{ ...paperContainer }}>
              <Map />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Home;
