import StationDataFields from '../data/filtered_stations_datafields.json';

export interface IStationDataField {
  Site: string| number ; //
  Dist: number | string | null;
  'Site name': string;
  Start: number | null;
  End: string;
  Lat: number | null;
  Lon: number | null;
  Source: string;
  STA: string;
  'Height (m)': number | null;
  Bar_ht: number | string | null;
  WMO: number | null;
  TimeZone: string;
  dateFrom: number | null;
  dateTo: string;
  UTC: string;
  localTime: string;
  tempDry: string;
  tempWet: string;
  tempDew: string;
  tempTerrain: string;
  tempWater: string;
  relHumidity: string;
  rainCum: string;
  rainMin: string;
  speedAve: string;
  speedMax: string;
  speedMin: string;
  speedStd: string;
  speedNo: string;
  dirAve: string;
  dirMax: string;
  dirMin: string;
  dirStd: string;
  dirNo: string;
  pressure: string;
  cloud1: string;
  cloud2: string;
  cloud3: string;
  cloud4: string;
  cloud5: string;
  OKTA1: string;
  OKTA2: string;
  OKTA3: string;
  visibility: string;
  metar1: string;
  metar2: string;
  metar3: string;
  metar4: string;
  synop1: string;
  synop2: string;
  error: string;
  waterLevel1: string;
  waterLevel2: string;
  waterLevel3: string;
  thunderCount: string;
}

export const getDataFields = (
  station_id: string|number
): IStationDataField | undefined => {
  const list: IStationDataField[] = StationDataFields;
  const result = list.find((i) => i.Site === station_id);
  return result;
};
