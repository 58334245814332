import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import MotionPhotosAutoIcon from '@mui/icons-material/MotionPhotosAuto';
import {
  AppBar,
  Avatar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
// declarations.d.ts
import { ArcButton, ArcNavbar, ArcContainer } from '@arc-web/components';
import { DOMAttributes } from 'react';

import { authToken } from '../../auth';
import Arup from '../../images/logos/Arup.svg';

type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'arc-container': CustomElement<ArcContainer>;
      'arc-navbar': CustomElement<ArcNavbar>;
      'arc-button': CustomElement<ArcButton>;
    }
  }
}

interface HeaderProps {}

const Header: React.FunctionComponent<HeaderProps> = () => {
  const { instance, accounts } = useMsal();
  const [avatar, setAvatar] = useState('');
  useEffect(() => {
    const getAvatar = async () => {
      const avatarRes = await fetch(
        'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
        {
          headers: {
            Authorization: await authToken(instance, accounts),
            'Content-Type': 'image/jpg',
          },
        }
      );
      setAvatar(URL.createObjectURL(await (avatarRes as any).blob()));
    };
    accounts.length > 0 && getAvatar();
  }, [instance, accounts]);

  // const handleLogout = () => {
  //   instance.logoutRedirect({
  //     postLogoutRedirectUri: '/',
  //   });
  // };

  return (
    <AppBar
      position='static'
      color='secondary'
      style={{ borderBottom: '1px solid #DDDDDD' }}
    >
      <Toolbar>
        <Typography variant='h4' component='div' sx={{ flexGrow: 1 }}>
          Weather Observation Data Platform
          <Typography
            component='sup'
            variant='overline'
            color={'primary'}
            sx={{ ml: 0.5 }}
          >
            Beta
          </Typography>
        </Typography>
        {/* <Tooltip title='register api' placement='bottom' sx={{ mr: 2 }}>
          <IconButton
            color='primary'
            edge='start'
            aria-label='register api'
            target={'_blank'}
            href='https://forms.office.com/pages/responsepage.aspx?id=QYvkSjcBmUWGYfxkH-d76rI9zTqjDK1FhW0pL7B0NmFUNEhYM1VMSVVFUE5DSkZRWjM4Nk1aWTVXMy4u'
          >
            <MotionPhotosAutoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='feedback' placement='bottom' sx={{ mr: 2 }}>
          <IconButton
            color='primary'
            edge='start'
            aria-label='feedback'
            target={'_blank'}
            href='https://forms.office.com/pages/responsepage.aspx?id=QYvkSjcBmUWGYfxkH-d76rI9zTqjDK1FhW0pL7B0NmFUQ0c5NDBaSUlETlQxMVVEWlpDUE9PQlY1Ti4u'
          >
            <ChatIcon />
          </IconButton>
        </Tooltip> */}
        <Avatar alt='avatar' src={avatar} />
        <Typography variant='body2' color='black' sx={{ ml: 2, mr: 2 }}>
          {accounts.length > 0 ? accounts[0].username : null}
        </Typography>
        <img alt='Arup' src={Arup} height={22} />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
