import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAppDispatch } from '../../redux/hooks';
import { Grid } from '@mui/material';
import 'dayjs/locale/en-au';
import { updateDateValue } from '../../redux/features/stationSlice';
import { DateTimePicker } from '@mui/x-date-pickers';
type DatePickerProps = {
  value: string[];
  minValue: number;
  maxValue: number;
};

function MUIDatePicker({ value, minValue, maxValue }: DatePickerProps) {
  const dispatch = useAppDispatch();

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    const [start, end] = value || [];
    if (start) {
      setStartDate(dayjs(start));
    } else {
      setStartDate(null);
    }
    if (end) {
      setEndDate(dayjs(end));
    } else {
      setEndDate(null);
    }
  }, [value]);

  const updateStartDate = (date: Dayjs | null) => {
    setStartDate(date);
    dispatch(
      updateDateValue({
        name: 'start',
        value: date ? date.format('YYYY/MM/DD HH:mm:ss') : '',
      })
    );
  };
  const updateEndDate = (date: Dayjs | null) => {
    setEndDate(date);
    dispatch(
      updateDateValue({
        name: 'end',
        value: date ? date.format('YYYY/MM/DD HH:mm:ss') : '',
      })
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-au'>
      <Grid
        container
        direction='row'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        rowSpacing={4}
      >
        <Grid item md={5}>
          <DateTimePicker
            value={startDate}
            minDate={dayjs('2024/08/23')}
            maxDate={dayjs(new Date())}
            onChange={updateStartDate}
            renderInput={(params) => (
              <TextField {...params} helperText={null} size='small' />
            )}
          />
        </Grid>
        <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          to
        </Grid>
        <Grid item md={5}>
          <DateTimePicker
            value={endDate}
            minDate={dayjs('2024/08/23')}
            maxDate={dayjs(new Date())}
            onChange={updateEndDate}
            renderInput={(params) => (
              <TextField {...params} helperText={null} size='small' />
            )}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

export default React.memo(MUIDatePicker);
