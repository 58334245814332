import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import {
  selectDatasets,
  stationStateRoot,
  updateDataFields,
} from '../../redux/features/stationSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import DataFieldsConfig from '../../utils/constants';
import DataFieldsCategory, { LabeValue } from './DataFieldsCategory';
import ToolTipLabel from './TooltipLabel';
interface DataFieldsProps {}

const DataFields: React.FunctionComponent<DataFieldsProps> = () => {
  const [open, setOpen] = useState(false);
  const [selectType, setSelectType] = useState<string>('');
  const dispatch = useAppDispatch();
  const stateRoot = useAppSelector(stationStateRoot);
  const { dataFields: stateDataFields = {} } = stateRoot;

  const currentStation = stateRoot.downloadStations[0];
  const { station_id } = currentStation || {};

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //TODO
  let selectDataFields: string[] = [];
  const dataFieldsValues = Object.values(stateRoot.dataFields);

  if (dataFieldsValues && dataFieldsValues.length > 0) {
    selectDataFields = dataFieldsValues
      .reduce((a, b) => [...a, ...b])
      .map((p) => p.label);
  }
  console.log('aaa-1', JSON.stringify(dataFieldsValues));

  const handleChange = (title: string, list: LabeValue[]) => {
    dispatch(updateDataFields({ title, list }));
    setSelectType('');
  };

  const getInitValue = (title: string): string[] => {
    const list = stateDataFields[title];
    if (!list || !list.length) {
      return [];
    }
    return list.map((p) => p.value);
  };
  //TODO
  const selectAllDatasets = (type: string) => {
    setSelectType(type === selectType ? '' : type);
    dispatch(selectDatasets({ type, station_id }));
  };
  return (
    <>
      <ToolTipLabel label='Data fields' />
      <Box
        onClick={handleClickOpen}
        style={{
          minHeight: 46,
          border: '1px solid #ddd',
          borderRadius: 5,
          padding: 3,
        }}
      >
        {selectDataFields.length === 0 && (
          <Typography variant='subtitle2' sx={{ mt: 1, ml: 1 }}>
            Click to select
          </Typography>
        )}
        {selectDataFields.join(',')}
      </Box>
      <Dialog
        maxWidth={false}
        fullWidth={true}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant='h3'>Select data fields</Typography>
          <Typography variant='subtitle2'>
            Select specific data fields for download (Note: if specific data
            field is not available to the station, it is greyed out)
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component='form'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              height: 'fit-content',
            }}
          >
            <Grid>
              <Chip
                label='All datasets'
                variant='outlined'
                color={selectType === 'all' ? 'primary' : 'default'}
                onClick={() => selectAllDatasets('all')}
                sx={{ mr: 1 }}
              />

              <Chip
                label='Wind datasets'
                variant='outlined'
                color={selectType === 'wind' ? 'primary' : 'default'}
                onClick={() => selectAllDatasets('wind')}
                sx={{ mr: 1 }}
              />
              <Chip
                label='Acoustic datasets'
                variant='outlined'
                color={selectType === 'acoustic' ? 'primary' : 'default'}
                onClick={() => selectAllDatasets('acoustic')}
                sx={{ mr: 1 }}
              />
              <Chip
                label='Data centre datasets'
                variant='outlined'
                color={selectType === 'dataCentre' ? 'primary' : 'default'}
                onClick={() => selectAllDatasets('dataCentre')}
              />
            </Grid>
            <Grid container rowSpacing={3} sx={{ display: 'flex' }}>
              <Grid item md={2} sx={{ mb: 2 }}>
                <DataFieldsCategory
                  title='Temperature'
                  stationId={station_id}
                  initValue={getInitValue('Temperature')}
                  list={DataFieldsConfig.Temperature}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>
              <Grid item md={2}>
                <DataFieldsCategory
                  title='Humidity'
                  stationId={station_id}
                  initValue={getInitValue('Humidity')}
                  list={DataFieldsConfig.Humidity}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>
              <Grid item md={2}>
                <DataFieldsCategory
                  title='Wind Speed'
                  stationId={station_id}
                  initValue={getInitValue('Wind Speed')}
                  list={DataFieldsConfig['Wind Speed']}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>
              <Grid item md={2}>
                <DataFieldsCategory
                  title='Wind Direction'
                  stationId={station_id}
                  initValue={getInitValue('Wind Direction')}
                  list={DataFieldsConfig['Wind Direction']}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>

              <Grid item md={2}>
                <DataFieldsCategory
                  title='Rainfall'
                  stationId={station_id}
                  initValue={getInitValue('Rainfall')}
                  list={DataFieldsConfig.Rainfall}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>
              <Grid item md={2}>
                <DataFieldsCategory
                  title='Pressure'
                  stationId={station_id}
                  initValue={getInitValue('Pressure')}
                  list={DataFieldsConfig.Pressure}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>
              <Grid item md={2}>
                <DataFieldsCategory
                  title='Cloud Level'
                  stationId={station_id}
                  initValue={getInitValue('Cloud Level')}
                  list={DataFieldsConfig['Cloud Level']}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>
              <Grid item md={2}>
                <DataFieldsCategory
                  title='Okta'
                  stationId={station_id}
                  initValue={getInitValue('Okta')}
                  list={DataFieldsConfig.Okta}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>
              <Grid item md={2}>
                <DataFieldsCategory
                  title='Visibility'
                  stationId={station_id}
                  initValue={getInitValue('Visibility')}
                  list={DataFieldsConfig.Visibility}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>
              <Grid item md={2}>
                <DataFieldsCategory
                  title='Metar'
                  stationId={station_id}
                  initValue={getInitValue('Metar')}
                  list={DataFieldsConfig.Metar}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>
              <Grid
                item
                md={2}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <DataFieldsCategory
                  title='Synop'
                  stationId={station_id}
                  initValue={getInitValue('Synop')}
                  list={DataFieldsConfig.Synop}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>

              <Grid item md={2}>
                <DataFieldsCategory
                  title='Error'
                  stationId={station_id}
                  initValue={getInitValue('Error')}
                  list={DataFieldsConfig.Error}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>

              <Grid item md={2}>
                <DataFieldsCategory
                  title='Water Level'
                  stationId={station_id}
                  initValue={getInitValue('Water Level')}
                  list={DataFieldsConfig['Water Level']}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>

              <Grid item md={2}>
                <DataFieldsCategory
                  title='Thunder Count'
                  stationId={station_id}
                  initValue={getInitValue('Thunder Count')}
                  list={DataFieldsConfig['Thunder Count']}
                  onChange={(title, res) => handleChange(title, res)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose}>
            Confirm Selections
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DataFields;
