import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { getDataFields, IStationDataField } from '../../utils/dataFieldsHelper';
export interface LabeValue {
  label: string;
  value: string;
}
interface DataFieldsCategoryProps {
  title: string;
  list: LabeValue[];
  initValue: string[];
  stationId: string;
  onChange: (title: string, e: LabeValue[]) => void;
}

const DataFieldsCategory: React.FunctionComponent<DataFieldsCategoryProps> = (
  props
) => {
  const { initValue, title, list, onChange, stationId } = props;
  const [result, setResult] = useState<LabeValue[]>([]);
  const [dataFieldInfo, setDataFieldInfo] = useState<
    IStationDataField | null | undefined
  >(null);
  useEffect(() => {
    if (!stationId) {
      return;
    }
    const tmp = getDataFields(stationId);
    setDataFieldInfo(tmp);
  }, [stationId]);

  useEffect(() => {
    if (!list || !list.length) {
      return;
    }

    let tmpData: LabeValue[] = [];
    list.forEach((item, index) => {
      tmpData[index] = { label: '', value: '' };
      if (initValue && initValue.length > 0 && initValue.includes(item.value)) {
        tmpData[index] = item;
      }
    });
    setResult(tmpData);
  }, [list, initValue]);

  const checkDisabled = (fieldName: string): boolean => {
    if (!dataFieldInfo) {
      return false;
    }
    //@ts-ignore
    const val = dataFieldInfo[fieldName];
    const res = (val || '').toUpperCase() !== 'FALSE' ? false : true;
    return res;
  };

  const handleCheck = (index: number, value: LabeValue, checked: boolean) => {
    let tmpData = [...result];
    if (result[index] && result[index].value) {
      tmpData[index] = { label: '', value: '' };
    } else {
      tmpData[index] = value;
    }
    setResult(tmpData);
    if (onChange) {
      onChange(
        title,
        tmpData.filter((p) => p.value)
      );
    }
  };
  const handleCheckAll = (checked: boolean) => {
    let tmpData: LabeValue[] = [];
    if (checked) {
      list.forEach((item, index) => {
        if (checkDisabled(item.value)) {
          tmpData[index] = { label: '', value: '' };
        } else {
          tmpData[index] = { ...item };
        }
      });
    } else {
      tmpData = [];
    }
    setResult(tmpData);
    if (onChange) {
      onChange(
        title,
        tmpData.filter((p) => p.value)
      );
    }
  };
  const currentSelectCheckbox = result.filter((p) => p.value).length;
  const checkedAll =
    currentSelectCheckbox > 0 &&
    currentSelectCheckbox ===
      list.filter((p) => !checkDisabled(p.value)).length;
  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedAll}
            onChange={(e) => handleCheckAll(e.target.checked)}
          />
        }
        label={title}
        sx={{
          '& .MuiSvgIcon-root': { fontSize: 20 },
          '& .MuiTypography-root': { fontSize: 24 },
        }}
      />

      {list.map((item, index: number) => {
        const checked = result[index] && result[index].value ? true : false;
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                disabled={checkDisabled(item.value)}
                name={item.value}
                value={item.value}
                checked={checked}
                onChange={(e) => handleCheck(index, item, e.target.checked)}
              />
            }
            label={item.label}
            sx={{
              '& .MuiSvgIcon-root': { fontSize: 20 },
              '& .MuiTypography-root': { fontSize: 16 },
            }}
          />
        );
      })}
    </Grid>
  );
};

export default DataFieldsCategory;
