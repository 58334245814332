import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../redux/hooks';
import { selectLoading } from '../../redux/features/settingSlice';

interface LoadingProps {}

const Loading: React.FunctionComponent<LoadingProps> = (props) => {
  const loading = useAppSelector(selectLoading);

  return (
    <Backdrop sx={{ color: 'secondary.main', zIndex: 10 }} open={loading}>
      <CircularProgress color='inherit' />
      <Box
        sx={{
          top: 100,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='subtitle1' component='div' color='secondary.main'>
          {'Prepare the data, please wait...'}
        </Typography>
      </Box>
    </Backdrop>
  );
};

export default Loading;
