import { FeatureCollection, Feature, Point } from 'geojson';

export type Station = {
  Site: string | number | undefined;
  Dist: string;
  'Site name': string;
  Start: number | null;
  End: number | string | null;
  Lat: number;
  Lon: number;
  Source: string;
  STA: string | null;
  'Height (m)': number | null;
  Bar_ht: number | null;
  WMO: number | null;
  TimeZone: string |null;
  dateFrom: number |string| null;
  dateTo: number |string| null;
  selected?: boolean;
}

export type Stations = Station[]

export const jsonToGeojson = (jsonData: Stations):FeatureCollection => {
  const features: Feature[] = jsonData.map((s: Station) => {
    const { Site, Dist, Start, End, Source, STA, Bar_ht, WMO, TimeZone, dateFrom,dateTo } = s;
    const pointGeometry: Point = {
      type: 'Point',
      coordinates: [s.Lon, s.Lat],
    };
    return {
      type: 'Feature',
       id: `${Site}`,
      geometry: pointGeometry ,
      properties: {
        site: Site,
        dist: Dist,
        name: s['Site name'],
        sta: STA,
        start: Start,
        end: End,
        source: Source,
        height: s['Height (m)'],
        bar_ht: Bar_ht,
        wmo: WMO,
        dateFrom,
        dateTo,
        timezone: TimeZone,
        selected: false,
      },
    };
  });

  return {type: 'FeatureCollection', features}

}