export interface IPostStationData {
  requestUtc:string;
  requestTime: string;
  startDate: string;
  endDate: string;
  userEmail: string;
  interval: string;
  stations: string[];
  stationName: string[];
  columns: string[];
}

export const downloadCSV = async (data:IPostStationData)=>{
  const url_short = process.env.REACT_APP_URL_SHORT as string;

  const response = await fetch(url_short, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  const res: any = await response.text();

  const content = encodeURIComponent(res);
  const uri = 'data:text/csv:charset=utf-8,\ufeff' + content;
  const link = document.createElement('a');
  link.href = uri;
  const fileName = `${data.stationName }_${data.startDate}_${data.endDate}_${data.interval}`;

  link.download = `${fileName}.csv`;
  let event;
  if (window.MouseEvent) {
    event = new MouseEvent('click');
  } else {
    event = document.createEvent('MouseEvent');
    event.initMouseEvent(
      'click',
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
  }
  link.dispatchEvent(event);

}


export const downloadViaEmail = async(data:IPostStationData):Promise<string> =>{
  const url = process.env.REACT_APP_URL_LONG as string;

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  const message:string =  await response.text();
  return message;
}
